import React, { useEffect } from "react"
import Helmet from "react-helmet"
import HomepageLayout from "src/layouts/homepage"
import JSONData from "branding/site-metadata.json"
import RawFooter from "@tightrope/footerlinks/rawfooter.js"
import footerStyles from 'src/styles/shared/footer.module.scss';
import styles from 'src/styles/shared/legal-pages.module.scss';


export default function Terms() {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://app.termly.io/embed-policy.min.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);  return (
        <HomepageLayout>
            <Helmet data={JSONData}><title>Terms of Service</title></Helmet>
            <div className={styles.legalPage}>
                <header>
                    <img src="/img/mainlogo.png" alt="Main Logo" />
                </header>
                <div className={styles.container}>
                    <div
                        name="termly-embed"
                        data-id="4a358ceb-3ffb-41f7-be85-a8a16f907ff1"
                        data-type="iframe"
                    ></div>
                </div>
            </div>
            <RawFooter />
        </HomepageLayout>
    );
}
